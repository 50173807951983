import React from "react"
import { Layout } from "../components"

const Imprint = () => (
  <Layout>
    <div className="py-10">
      <h1 className="text-h1-mobile sm:text-h1-desktop font-cambon sm:mb-20">
        Impressum
      </h1>
      <p className="my-3">
        Gorilla Funds UG (haftungsbeschränkt), Hermann-Wellensiek-Str. 33, 67346
        Speyer, eingetragen im Handelsregister des Amtsgerichts Ludwigshafen
        unter der Nummer HRB 66803
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Umsatzsteuer-Identifikationsnummer (§ 27a des Umsatzsteuergesetzes):
      </h2>
      <p className="my-3">DE 832267832</p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        (Vertretungsberechtigter) / (Inhaber):
      </h2>
      <p className="my-3">
        Jakob Sievers <br />
        Postanschrift
        <br />
        Hermann-Wellensiek-Str. 33, 67346 Speyer
        <br />
        Kontaktdaten:
        <br />
        E-Mail: info@gorillashops.io
        <br />
        Telefon: 06232/8779574
        <br />
        Internetseite: https://gorillashops.io
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Weitere Angaben (Verantwortlicher gemäß § 18 Abs. 2
        Medienstaatsvertrag:)
      </h2>
      <p className="my-3">
        Jakob Sievers, Hermann-Wellensiek-Str. 33, 67346 Speyer
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Hinweis zur OS-Plattform:
      </h2>
      <p className="my-3">
        Wir sind aufgrund von Art. 14 Abs. 1 der Verordnung (EU) 524/2013 über
        Online- Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung)
        gesetzlich verpflichtet, Sie auf die Europäische
        Online-Streitbeilegungs-Plattform (OS-Plattform) der Europäischen
        Kommission hinzuweisen. Sie können diese unter
        <a href="http://ec.europa.eu/odr" target="_blank" rel="noreferrer">
          http://ec.europa.eu/odr
        </a>
        .Die Gorilla Funds UG nimmt nicht an einem Streitbeilegungsverfahren vor
        einer Verbraucherschlichtungsstelle teil und wir sind dazu auch nicht
        verpflichtet.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">Haftung</h2>
      <p className="my-3">
        Wir sind für die Inhalte unserer Internetseiten nach den Maßgaben der
        allgemeinen Gesetzen, insbesondere nach § 7 Abs. 1 des
        Telemediengesetzes, verantwortlich. Alle Inhalte werden mit der
        gebotenen Sorgfalt und nach bestem Wissen erstellt. Soweit wir auf
        unseren Internetseiten mittels Hyperlink auf Internetseiten Dritter
        verweisen, können wir keine Gewähr für die fortwährende Aktualität,
        Richtigkeit und Vollständigkeit der verlinkten Inhalte übernehmen, da
        diese Inhalte außerhalb unseres Verantwortungsbereichs liegen und wir
        auf die zukünftige Gestaltung keinen Einfluss haben. Sollten aus Ihrer
        Sicht Inhalte gegen geltendes Recht verstoßen oder unangemessen sein,
        teilen Sie uns dies bitte mit.
      </p>
      <h1 className="text-h1-mobile sm:text-h1-desktop font-cambon sm:mb-20">
        Imprint
      </h1>
      <p className="my-3">
        Gorilla Funds UG (limited liability), Hermann-Wellensiek-Str. 33, 67346
        Speyer, entered in the commercial register of the district court of
        Ludwigshafen under number HRB 66803)
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Sales tax identification number (§ 27a of the sales tax law):
      </h2>
      <p className="my-3">DE832267832</p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        (authorized representative) / (owner):
      </h2>
      <p className="my-3">
        Jakob Sievers Postal address: Hermann-Wellensiek-Str. 33, 67346 Speyer
        Contact details: Email: info@gorillashops.io Telephone: 06232/8779574
        Website: https://gorillashops.io
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        More information (Responsible according to § 18 Abs. 2
        Medienstaatsvertrag:)
      </h2>
      <p className="my-3">
        Jakob Sievers, Hermann-Wellensiek-Str. 33, 67346 Speyer
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8">
        Note on the OS platform:
      </h2>
      <p className="my-3">
        Based on Art. 14 Para. 1 of Regulation (EU) 524/2013 on online dispute
        resolution in consumer matters (ODR Regulation), we are legally obliged
        to refer you to the European online dispute resolution platform (OS
        platform) of the European Commission. You can reach them at
        <a href="http://ec.europa.eu/odr" target="_blank" rel="noreferrer">
          http://ec.europa.eu/odr
        </a>
        .Gorilla Funds UG does not participate in a dispute settlement procedure
        before a consumer arbitration board and we are not obliged to do so.
      </p>
      <h2 className="text-h4-mobile sm:text-h4-desktop my-8"> liability</h2>
      <p className="my-3">
        We are responsible for the content of our website in accordance with the
        provisions of general law, in particular Section 7 (1) of the Telemedia
        Act. All content is created with due care and to the best of our
        knowledge. Insofar as we refer to third-party websites on our website
        via hyperlinks, we cannot guarantee that the linked content will be up
        to date, correct, and complete, as this content is outside our area of
        responsibility and we have no influence on the future design. If you
        think that content violates applicable law or is inappropriate, please
        let us know.
      </p>
    </div>
  </Layout>
)

export default Imprint
